.parallax {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.parallax-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  }
  
  .parallax-background-left {
    left: 0;
    }
    
    .parallax-background-right {
      right: 0;
      }
      
      
      
      .parallax-content {
        background-color: rgba(0, 0, 0, 0.84);
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
        color: rgb(0, 0, 0);
        /* padding: 50px; */
        text-align: center;
        width: 100%;
        }
        .parallax-content-black{
          background-color: rgb(0, 0, 0);
          position: absolute;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          top: 0;
          color: white;
          padding: 50px;
          text-align: justify;
          width: 40%;
          flex-direction: column;
  
}
.parallax-content-black-left{
  left: 0;
}
.parallax-content-black-right{
  right: 0;
}
@media screen and (max-width: 767px) {
  .parallax {
    justify-content: center;
    height: 50vh;
  }
  .parallax-background {
    width: 100%;
  }
  .parallax-content {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
}
