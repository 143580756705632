@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.property-card {
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
}

.property-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-details {
  padding: 16px;
}

.property-address {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 8px;
  color: black;
}

.property-features {
  display: flex;
  margin-bottom: 8px;
}

.feature {
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: #565656;
}

.feature-icon {
  color: #565656;
  margin-right: 4px;
}

.property-size {
  display: flex;
  align-items: center;
  color: #565656;
  margin-bottom: 8px;
}

.size-icon {
  margin-right: 4px;
}

.property-type {
  font-size: 14px;
  color: #565656;
  margin-bottom: 16px;
}

.property-price {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 16px;
}

.view-details-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-details-btn:hover {
  background-color: rgb(157 128 54);
}