.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  
}
.feature-card-text {
  font-weight: 300;
  line-height: 28px;
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
    flex-direction: row;
    /* margin: auto; */
    /* padding: 56px; */
    margin-top: 120px;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
