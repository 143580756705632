.carousel-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carousel-slide {
  display: flex;
  width: 100%;
  height: 500px;
  transition: transform 0.7s ease-in-out;
}

.carousel-slide-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.carousel-nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.carousel-nav-btn.prev {
  left: 20px;
}

.carousel-nav-btn.next {
  right: 20px;
}

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 8px;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
}

.carousel-dot.active {
  background-color: white;
  transform: scale(1.2);
}

@media (max-width: 1024px) {
  .carousel-container {
    max-width: 100%;
  }

  .carousel-slide {
    height: 400px;
  }

  .carousel-nav-btn {
    width: 32px;
    height: 32px;
  }

  .carousel-nav-btn.prev {
    left: 10px;
  }

  .carousel-nav-btn.next {
    right: 10px;
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 768px) {
  .carousel-slide {
    height: 300px;
  }

  .carousel-nav-btn {
    display: none;
  }

  .carousel-dots {
    bottom: 10px;
  }

  .carousel-dot {
    width: 8px;
    height: 8px;
  }
}