/* style.css or home.css */
.navbarContainer {
    position: relative;
  }
  
  .home-desktop-menu {
    display: flex;
  }
  
  .home-burger-menu {
    display: none;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .home-desktop-menu {
      display: none;
    }
  
    .home-burger-menu {
      display: block;
    }
  
    .home-mobile-menu1 {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      backdrop-filter: blur(10px); /* Apply blur effect */
      background-color: black;
      width: 100%;
      z-index: 1000;
    }
  
    .home-close-menu {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }
  
    .home-links1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
  
    .home-links1 a {
      /* padding: 10px; */
      text-decoration: none;
      color: rgb(255 255 255);
    }
  
    .dropdown-menu {
      display: flex;
      flex-direction: column;
    }
  
    .dropdown-menu a {
      padding-left: 20px;
    }
  }
  