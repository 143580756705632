/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.blog-post-container {
  max-width: 800px;
  margin: 8rem auto 2rem; /* Added more top margin */
  padding: 1rem;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden; /* Ensures content doesn't overflow */
}

.blog-post-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #2c3e50;
}

.blog-post-author {
  font-style: italic;
  text-align: center;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
}

.blog-post-date {
  text-align: center;
  font-size: 0.9rem;
  color: #95a5a6;
  margin-bottom: 2rem;
}

.blog-post-section {
  margin-bottom: 2rem;
  word-wrap: break-word; /* Ensure words break if they are too long */
}

.section-heading {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #34495e;
}

.section-paragraph {
  margin-bottom: 1rem;
  line-height: 1.8;
  word-wrap: break-word; /* Ensure long words are wrapped within container */
}

.blog-post-section img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 1rem auto;
  object-fit: cover; /* Ensures uniformity in image display */
  max-height: 400px; /* Limits height for uniformity */
  width: 100%; /* Ensure images stay within the container */
  object-position: center; /* Keep image centered */
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-post-container {
    padding: 0.5rem;
  }

  .blog-post-title {
    font-size: 1.8rem;
  }

  .section-heading {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .blog-post-title {
    font-size: 1.5rem;
  }

  .section-heading {
    font-size: 1.2rem;
  }

  .blog-post-date {
    font-size: 0.8rem;
  }
}
