/* Blogtemplate.css */

/* General styling for the blog container */
.blog-container {
  padding: 50px;
  min-height: 100vh;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%; /* Adjusted for a wider space */
}

/* Grid setup for displaying multiple blog cards */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Makes it responsive */
  gap: 20px;
  margin-top: 20px;
}

/* Variations in card sizes to create a dynamic layout */
.blog-card.size-0 {
  grid-row: span 2; /* Larger card */
}

.blog-card.size-1 {
  grid-column: span 2; /* Wider card */
}

.blog-card.size-2 {
  grid-column: span 1;
  grid-row: span 1; /* Standard card */
}

/* Blog card styling */
.blog-card {
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  height: 100%; /* Ensures cards have consistent height */
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.blog-card:hover .blog-image {
  transform: scale(1.05);
}

/* Blog image styling */
.blog-image {
  position: relative;
  width: 100%;
  height: 200px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

/* Blog content container */
.blog-content {
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Ensure text stays at the bottom */
  height: 100%;
}

.blog-title {
  font-size: 1.6em;
  margin: 0 0 10px 0;
}

.blog-intro {
  margin: 0 0 15px 0;
  font-size: 1em;
}

.read-more-button {
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 10px;
}

.read-more-button:hover {
  background: #0056b3;
}

/* Responsive design */
@media (max-width: 900px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }

  .blog-container {
    max-width: 95%; /* More flexible on smaller screens */
  }
}

@media (max-width: 600px) {
  .blog-grid {
    grid-template-columns: 1fr; /* Single column layout for mobile */
  }

  .blog-card {
    height: auto; /* Auto height for mobile cards */
  }
}
