/* LoadingSpinner.css */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  .spinner-dot {
    width: 15px;
    height: 15px;
    margin: 0 8px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .spinner-dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .spinner-dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .spinner-dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  