.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 10px 27px;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s;

}

.dropdown-toggle:hover {
  background-color: #ccc;
  color: rgb(255, 255, 255)
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: rgba(12, 12, 12, 0.719); /* Semi-transparent white */
  /* background-color: #000000eb; */
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.471);
  z-index: 1;
  margin-top: 10px;
  backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
  -webkit-backdrop-filter: blur(5px); /* Safari support */
}

.dropdown:hover .dropdown-menu {
  /* display: block; */
}

.dropdown-item {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s, color 0.3s;
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #343434;
  color: white;
}


.home-container {
  background-color: #000000;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-container > * {
  max-width: 100%;
  box-sizing: border-box;
}

.home-header {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  background-color: #ffffff00;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.home-nav22 {
  margin-left: var(--dl-space-space-unit);
}
.home-nav32 {
  margin-left: var(--dl-space-space-unit);
}
.home-nav42 {
  /* margin-left: var(--dl-space-space-unit); */
}
.home-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  margin-right: 32px;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu1 {
  top: 0px;
  right: 0px;
  /* width: 100%; */
  /* height: 100vh; */
  display: none;
  padding: 32px;
  z-index: 1000;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  border-radius: inherit;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav121 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav221 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav321 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav421 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  /* margin-top: 96px; */
  align-items: flex-start;
  justify-content: center;
}
.home-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 50%;
}
.hero-content {
  position: relative; /* Ensures content is above the video */
  z-index: 1;
  text-align: center;
  color: white;
}


.home-hero-heading {
  max-width: 800px;
  text-align: center;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-features_dholera {
  flex: 0 0 auto;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  position: relative;
  /* align-items: center; */
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-features11 {
  position: relative;
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 220vh;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container02 {
  width: 80%;
  /* height: 100vh; */
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  border-radius: 25px;
  backdrop-filter: blur(10px); /* Apply blur effect */
  padding: 0px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Slight shadow for better contrast */
  position: relative;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container03 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  /* grid-template-columns: 1fr 1fr; */
}
.home-container03_HOME {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 767px) {
  .home-container03_HOME{
    grid-template-columns: 1fr;
  }
  
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-pricing-sub-heading {
  text-align: center;
}
.home-container05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text36 {
  text-transform: uppercase;
}
.home-container07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text37 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-free-plan-price {
  font-size: 4rem;
  font-weight: 700;
}
.home-container08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text40 {
  line-height: 150%;
}
.home-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text41 {
  line-height: 150%;
}
.home-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text42 {
  line-height: 150%;
}
.home-container12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text43 {
  line-height: 150%;
}
.home-button {
  width: 100%;
}
.home-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text44 {
  text-transform: uppercase;
}
.home-container14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text45 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-basic-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}
.home-text48 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-container15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-container16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text49 {
  line-height: 150%;
}
.home-container17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text51 {
  line-height: 150%;
}
.home-container18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text52 {
  line-height: 150%;
}
.home-container19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text53 {
  line-height: 150%;
}
.home-container20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text54 {
  line-height: 150%;
}
.home-button1 {
  width: 100%;
}
.home-container21 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text55 {
  text-transform: uppercase;
}
.home-container22 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-text58 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-pro-plan-pricing {
  font-size: 4rem;
  font-weight: 700;
}
.home-text61 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-container23 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text62 {
  line-height: 150%;
}
.home-container25 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text64 {
  line-height: 150%;
}
.home-container26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text65 {
  line-height: 150%;
}
.home-container27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text66 {
  line-height: 150%;
}
/* .home-container > * {
  background-color: #000000;
} */
.home-photos{
  background-color: #000000;
}
.home-container28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text67 {
  line-height: 150%;
}
.home-button2 {
  width: 100%;
}
.Emitence_dholera_news {
  width: 800px;
  height: auto;
  padding: 10px;
  display: flex;
  gap: 15px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure the container hides the overflow */
}

.Emitence_dholera_news img {
  transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
}

.Emitence_dholera_news:hover img {
  transform: scale(1.2); /* Zoom effect on hover */
}

.Emitence_dholera_2{
  width: 1600px;
  height: auto;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInSlideUp 1s ease-out forwards;
}

@keyframes fadeInSlideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 2168px) {
  .Emitence_dholera_2 {
    width: 60%;
  }
  
}
@media screen and (max-width: 1200px) {
  .Emitence_dholera_2 {
    width:100%;
  }

  
}
.white{
  color:white;
}
.goldText {
  color: rgb(251, 224, 74);
}
.whyDholeraCanvaBg{


  height: 100vh; /* Full height for the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.home-banner-heading {
  text-align: center;
  font-size:1.5rem;
  padding: 10px;
}
.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container29 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text85 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text86 {
  text-align: left;
}
.home-container30 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  /* position: fixed; */
  align-items: center;
  justify-content: center;
}
.home-container31 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-nav222 {
  margin-left: var(--dl-space-space-unit);
}
.home-nav322 {
  margin-left: var(--dl-space-space-unit);
}
.home-nav422 {
  margin-left: var(--dl-space-space-unit);
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon14 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
  }
  .home-banner-sub-heading {
    max-width: 100%;
  }
  .home-text86 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media (max-width:1200px) {
  .home-banner{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
  }
}
@media(max-width: 1417px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav321 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container03 {
    grid-template-columns: 1fr;
  }
  .home-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container29 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text86 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text89 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    margin-bottom: 0px;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text89 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}

.home-container-emitence-colab {
  
    background-image: url("/images/dholera_parallax.jpg"); /* Add your background image path */
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Parallax effect: Background moves slower than the content */
    height: 100vh; /* Full height for the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .home-container-emitence-colab1 {
  
    background-image: url("/images/Dholera_client.png"); /* Add your background image path */
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Parallax effect: Background moves slower than the content */
    height: 100vh; /* Full height for the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  

  .content-wrapper {
    width: 60%; /* Set the width to 60% */
    margin: 0 auto; /* Center the content horizontally */
    padding: 20px; /* Add padding inside the wrapper */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow effect */
    border-radius: 8px; /* Rounded corners */
    text-align: justify; /* Justify the text for better readability */
  }
  
  .content-wrapper h2 {
    font-size: 2.5rem; /* Larger font size for the heading */
    font-weight: 700; /* Bold heading */
    margin-bottom: 20px; /* Add space below the heading */
    text-align: center; /* Center the heading */
  }
  
  .content-wrapper p {
    font-size: 1.2rem; /* Font size for the paragraphs */
    line-height: 1.6; /* Increase line height for better readability */
    margin-bottom: 20px; /* Add space between paragraphs */
    color: #ffffff; /* Dark gray text color */
  }
  
  @media (max-width: 768px) {
    .content-wrapper {
      width: 80%; /* Adjust width for smaller screens */
      padding: 15px; /* Adjust padding */
    }
  
    .content-wrapper h2 {
      font-size: 2rem; /* Reduce heading size for smaller screens */
    }
  
    .content-wrapper p {
      font-size: 1rem; /* Adjust paragraph font size */
    }
  }
  

/* Styles for the prediction form */
.prediction-form {
  max-width: 400px;
  margin: 10% auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Style for input fields */
.prediction-form input {
  color: #000000;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Style for submit button */
.prediction-form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
}

/* Style for prediction result */
.prediction-result {
  color: #030303;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ececec;
}

.prediction-result h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #030303;
}


.mar{
  max-width: 1%;
}

.featuresContainer {
  flex: 0 0 auto;
  color: var(--dl-color-custom-neutral-light);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-custom-accent2);
}

@media screen and (max-width: 767px) {
  .featuresContainer {
    padding: 60px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-custom-accent2);
    align-items: stretch;
  }
  
}

/* PropertyCard.css */
.property-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  margin: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.property-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-card-details {
  padding: 16px;
}

.property-card-title {
  font-size: 1.5em;
  margin: 0 0 8px;
}

.property-card-price,
.property-card-type,
.property-card-location {
  margin: 4px 0;
  
}

.property_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.map{
  z-index: -0;
}
.leaflet-popup-content-wrapper {
  width: 250px; /* Adjust the width as needed */
}

.leaflet-popup-content {
  max-height: 3000px; /* Adjust the max-height as needed */
  overflow-y: auto;
}

/* Marker tooltip style */


/* Popup content style */
.leaflet-popup-content-wrapper {
  width: 200px; /* Adjust the width as needed */
  max-width: 100%;
}


.leaflet-popup-content img {
  max-width: 100%;
  height: auto;
}



/* src/SearchBar.css */
form {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: column;
}

input {
  width: 100%;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  border-radius: 20px;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1000;
}

.suggestions li {
  padding: 0.5rem;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #000000;
}

.pad{
  padding: 20px;
}

.ls{
  letter-spacing: 12px;
}
.dashes_to_line{
  text-decoration: line-through;
}

.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  flex: 0 1 30px;
  border-top: 1px solid rgb(255, 255, 255);
  margin: 0 10px;
}
