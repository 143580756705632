.property-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  }
.property-images{
    display: flex;
    padding-top: 130px;
    padding-bottom: 20px;
    width: 65%;
    justify-content:center;
} 
  
  .property-main-image {
    flex: 50%;
    overflow: hidden;
  }
  
  .property-main-image img {
    width: 100%;
    height: auto;
    border-radius: 25px;
  }
  
  .property-side-images {
    flex: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 25px;  
  }
  
  .property-side-images img {
    width: 100%;
    border-radius: 25px;
  }
  
  .property-side-images button {
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }

  .property-highlights{
    background-color: #111111;
    width: 65%;
    border-radius: 25px;
  }

  .pro-heading{
    padding: 20px;
  }

  td{
    padding: 10px;
    color: #fff;
    background-color: 000;
  }
  
  table{
    border-collapse: collapse;
    border-radius: 25px;
    margin: 20px;
    width: 100%;
  }

  tr{
    align-items: center;
  }