.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-image{
  float: left;
  width: 210px;
  height: 210px;
  background-size: cover;
}
.feature-card-text {
  font-weight: 600;  
  line-height: 28px;
}
.flow-chart{
  width: 600px;
  height: auto;
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
