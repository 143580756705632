.news-carousel {
  width: 90%;
  margin: auto;
  padding: 20px 0;
  background: linear-gradient(136deg, #000000, #010733);
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.carousel-card {
  padding: 10px;
  transition: transform 0.3s ease;
}

.carousel-card:hover {
  transform: scale(1.05);
}

.card-content {
  background: #0f172a;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  height: 400px; /* Increased height of the card */
  display: flex;
  flex-direction: column;
}

.card-image {
  width: 100%;
  height: 250px; /* Increased height of the image */
  object-fit: cover;
  border-bottom: 2px solid #1e293b;
}

.card-text {
  padding: 15px;
  color: white;
  flex-grow: 1; /* Ensures text grows and centers vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-text h3 {
  font-size: 1.4rem;
  margin: 0.5rem 0;
  color: #ffffff;
}

.card-text p {
  font-size: 1rem;
  color: #d1d5db;
  margin: 0;
}
