.footer {
  background: linear-gradient(1deg, #050d1b, #000000);
  color: #f5f5f5;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: auto;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.footer-column h3 {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 15px;
  position: relative;
}

.footer-column h3::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background: #ffd700;
  bottom: -5px;
  left: 0;
}

.footer-links {
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  margin: 10px 0;
}

.footer-links a {
  color: #f5f5f5;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #f7e78d;
}

.social-links {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.social-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #f5f5f5;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.social-icon i {
  font-size: 1.5rem;
}

.social-icon.facebook:hover {
  color: #4267B2;
}

.social-icon.instagram:hover {
  color: #E4405F;
}

.social-icon.youtube:hover {
  color: #FF0000;
}

.social-icon.email:hover {
  color: #ffd700;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #333;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
  color: #bbb;
}

.loader,
#overlayer {
  display: none; /* Hides loader by default */
}

@media (max-width: 768px) {
  .footer-row {
    text-align: center;
    gap: 10px;
  }
  .footer-column {
    flex: 1 1 100%;
  }
  .footer-column h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }
  .footer-column h3 {
    font-size: 1.2rem;
  }
  .footer-links li {
    margin: 5px 0;
  }
}