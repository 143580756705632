.contact-us {
    font-family: Arial, sans-serif;
    background-color: #000;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    animation: fadeIn 1s ease;
    flex-wrap: wrap; /* Add this line */
    color: #fff;
  }
  
  .location-info {
    width: 40%;
    background-color: #000000;
    padding: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    animation: slideInLeft 1s ease;
  }
  
  .contact-form {
    width: 55%;
    background-color: #000000;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideInRight 1s ease;
  }
  
  .contact-form h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fff;
  }
 
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
  }
  
  .contact-form textarea {
    height: 100px;
  }
  
  .contact-form button {
    background-color: #313131;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* animation: pulse 1s infinite; */
  }
  
  .contact-form button:hover {
    background-color: #45a049;
  }
  
  .typology-options {
    display: flex;
    align-items: center;
    color: #fff;
    flex-wrap: wrap; /* Add this line */
  }
  
  .typology-options label {
    margin-right: 20px;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .contact-us {
      flex-direction: column; /* Stack elements vertically on smaller screens */
    }
  
    .location-info,
    .contact-form {
      width: 100%; /* Make both sections full width */
    }
  
    .location-info {
      margin-bottom: 20px; /* Add some spacing between sections */
    }
  }
  


  

  