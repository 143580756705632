/* Redesigned Popup Styles */
body.unique-popup-active .home-container {
    filter: blur(5px);
    pointer-events: none;
}

/* Popup Overlay */
.unique-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

/* Popup Container */
.unique-popup-container {
    background: linear-gradient(163deg, #000000, #0d1121);
    padding: 40px;
    border-radius: 12px;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    color: #ffffff;
    overflow: hidden;
}

/* Close Button */
.unique-popup-close {
    background: none;
    border: none;
    color: #333;
    font-size: 28px;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.unique-popup-close:hover {
    color: #002b44;
}

/* Heading */
.unique-popup-container h2 {
    font-size: 26px;
    font-weight: 600;
    color: #333;
    margin-bottom: 25px;
    text-align: center;
}

/* Form Group */
.unique-form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; /* Stack the label and input */
}

/* Labels */
.unique-form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
}

/* Input Fields */
.unique-form-group input {
    width: 100%;
    padding: 14px 18px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fafafa;
    color: #000000;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.unique-form-group input::placeholder {
    color: #aaa;
}

.unique-form-group input:focus {
    outline: none;
    border-color: #56ccf2;
    box-shadow: 0 0 8px rgba(86, 204, 242, 0.4);
}

/* Submit Button */
.unique-submit-button {
    width: 100%;
    padding: 14px;
    background: linear-gradient(145deg, #56ccf2, #2f80ed);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.unique-submit-button:hover {
    background: linear-gradient(145deg, #2f80ed, #56ccf2);
}

.unique-submit-button:active {
    transform: scale(0.95);
}

/* Responsive Design */
@media (max-width: 480px) {
    .unique-popup-container {
        width: 95%;
        padding: 30px;
    }

    .unique-popup-container h2 {
        font-size: 22px;
    }

    .unique-form-group input,
    .unique-submit-button {
        font-size: 14px;
        padding: 12px;
    }
}
